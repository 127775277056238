<template>
    <div class="cross-detect">
        <div class="search-result line-center width-percent80">
            <div class="search-result2" v-show="searchResult2&&radio==radioFlag"></div>
            <div class="body listshow1" ref="bodyData" v-show="searchShows">
                <!-- 文章显示区域 -->
                <div class="line-center" style="width:80%;background:#ffffff;"><p class="title-top">企业名录</p></div>
                <div ref="from" v-loading="loading" class="show-area line-center width-percent80">
                    <!-- 企业名录 -->
                    
                    <div>
                        <dl class="show-list" v-for="(item,index) in respData6" :key="index">
                            <dt v-html="item.Title"></dt>
                            <dd class="show-lists"><img :src="item.DescribeImage" /><p v-html="item.DescribeText"></p></dd>
                            <a :href="item.DetailUrl" target="_blank">详情:{{item.DetailUrl}}</a>
                        </dl>
                    </div>
                </div>
                <!-- 按钮区域 -->
                <div v-show="respTotal>0&&respTotal2>0?true:false">
                    <div class="foot width-percent80 line-center">    
                        <el-pagination
                            background
                            @current-change="handleCurrentPageChange"
                            layout="prev, pager, next"
                            :page-size="pageSize"
                            :page-count="page_count">
                        </el-pagination>
                    </div>
                </div>
            </div>
        </div>
        <div class="initDiv" v-show="initDiv">

        </div>
    </div>
</template>
<script>
import axios from "axios";
import { from } from 'apollo-link';
export default {
    data(){
        return{
            // 企业名录
            respData6:[],
            pageSize: 10, // 每页默认显示10条数据
            currentPage: 1, // 当前页
            page_count: 0, // 总页数默认为0
            loading: false, 
            searchShows:false, // 搜索后显示结果，默认关闭
            initDiv: true,
            radioBrother: 0, //按钮显示框 与radio默认值同步
            respTotal: 0, // 返回的搜索数据总条数，默认值 0
            respTotal2: 0, // 返回的数据 rows 中的 length
            searchResult2: false, // 搜索无结果提示框
        }
    },
    created(){
        this.requireApi();
    },
    mounted() {
       
    },
    methods:{
        // 当前页发生变化
        handleCurrentPageChange(val){
            console.log("this page: ",val)
            this.currentPage = val;
            this.requireApi();
        },
        requireApi(){
            console.log(this.currentPage,this.pageSize)
            
            this.searchShows = true;
            this.loading = true;
            this.searchResult2 = false;
            
            const formData = new FormData(); //为XHR传参提供便利
            
            formData.append("page", this.currentPage);
            formData.append("count", this.pageSize);
            formData.append("keywords","");
            formData.append("type", 6);
            console.log("企业名录： ",formData);
            
            axios.post(this.api.LoginURL.concat("/common/unifiedSearch"),formData).then(res=>{
                console.log("res: ",res);
                this.radioFlag = this.radio; // 赋值给未发送请求的内容展示框类型值
                let temp_tip = document.getElementsByClassName('search-result2');
                if(res.data.success){
                    if(res.data.data == null || res.data.data.total == 0){
                        this.searchResult2 = true
                        console.log("temp_tip: ",temp_tip);
                        temp_tip[0].innerText = '暂无搜索结果';
                        temp_tip[0].style.color = "#999999";
                        temp_tip[0].style.fontSize = '16px';
                        temp_tip[0].style.textAlign = 'center';
                    }

                    this.page_count = Math.ceil(res.data.data.total/10.0);
                    console.log('this.page_count',this.page_count);
                    console.log("loading开始")
                    const data = res.data.data.rows;
                    this.respTotal = res.data.data.total;
                    this.respTotal2 = res.data.data.rows.length;
                    this.saveRespResult(data);
                    console.log("loading即将关闭")
                }else{
                    this.searchResult2 = true
                    temp_tip[0].innerText = res.data.respMsg;
                    temp_tip[0].style.color = "#999999";
                    temp_tip[0].style.fontSize = '14px';
                    temp_tip[0].style.textAlign = 'center';
                    this.respTotal = 0; // 没返回搜索结果条数 赋0
                    this.searchShows = false;
                    this.loading = false;
                    this.initDiv = false;
                    this.radioBrother = this.radio; // this.radioBrother =  type?
                }
                
            }).catch(error=>{ 
                console.log(error);
            })
        },
        // 处理返回数据
        saveRespResult(data){                           
            this.respData6 = [];
            for(let i=0;i<data.length;i++){
                    this.respData6.push({
                        // {Title: "",DescribeText: "",DescribeImage: "",DetailUrl: ""}
                        DescribeImage: data[i].DescribeImage,
                        DescribeText: data[i].DescribeText,
                        Title:data[i].Title,
                        DetailUrl: data[i].DetailUrl
                        // coverImg: this.api.LoginURL.concat(data[i].coverImg),
                    })
                }
            this.loading = false;
            this.initDiv = false;
        }
    }
}
</script>
<style  scoped>
/* public style */
body{
    margin: 0;
    padding: 0;
}
body div{
    box-sizing: border-box;
}
h2 {
    margin-block-start: 0;
    margin-block-end: 0;
    padding-top: 20px;
    padding-bottom: 20px;
}
.width-percent80{
    width: 80%;
}
.line-center{
    margin: 0 auto;
}
.center{
    text-align: center;
}
ul{
    padding-inline-start: 0;
}

/* private style */
.title{
    color: #f9fafb;
    letter-spacing: 10px;
}
.title span{
    vertical-align: 10px;
    margin-left: 11px;
    font-size: 0.169118rem;
}
.title:hover{
    color:  #f9fafb;
}
.cross-detect{
    box-sizing: border-box;
}
.top{
    /* background: #f7f7f7; */
    background-image: url('~@/assets/cross_detect_bkgd.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
    /* font-size: 0; */
}
.industry{
    padding-top: 50px;
    padding-bottom: 50px;
}
.container{
    
    
}

.show-area{
    background: white;
}
.bounder{
    height: 20px;
    background-color:  #6b8dcd;
}
.lists{
    float: left;
    width: 15%;
    border: 1px solid #76bdff;
    text-align: center;
    color: #0463bd;
    height: 24px;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    margin-bottom: 15px;
    background-color: white;
    cursor: pointer;

}
.lists:not(:nth-child(6n+1)){
    margin-left: 14px;
}
.industry{
    margin-bottom: 30px;
    margin-top: 30px;
}
.industry::after{
    display: block;
    content: '';
    clear: both;
}
.search-area{
    padding-bottom: 50px;
    
}
.show-area{
    padding-left: 20px;
}
.radio{
    margin-top: 50px;
    margin-right: -100px;
}
.show-list{
    border-bottom: 1px dashed #949494;
    padding-bottom: 15px;
}
.show-list .show-lists{
    margin-inline-start: 0px;
}
.show-list img{
    /* float: left; */
    vertical-align: top;
    max-width: 150px;
    height: auto;
    margin-right: 10px;

}
.show-list dt{
    font-size: 16px;
    line-height: 49px;
    letter-spacing: 2px;
    /* word-spacing: normal; */
    overflow: hidden;
    text-overflow: ellipsis;
    width: 73%;
    height: 49px;
    white-space: nowrap;
    font-family: "Arial","Microsoft YaHei","黑体","宋体",sans-serif;

}
.show-lists p {
    display: inline-block;
    font-size: 13px;
    word-break: break-all;
    width: 79%;
    height: 95px;
    /* background: #79797f; */
    line-height: 24px;
    font-family: Helvetica, 'Hiragino Sans GB', 'Microsoft Yahei', '微软雅黑', Arial, sans-serif;
    letter-spacing: 1px;
    margin-block-start: 0;
    margin-block-end: 0;
    overflow: hidden;
}
.foot{
    background-color: #f5f5f7;
    padding-top: 50px;
    padding-bottom: 50px;
    text-align: center;
    width: 90%;

}
.search-result,.foot{
    margin-top: 50px;
}
.initDiv{
    height: 300px;
}
.title-top{
    font-size: 15px;
    letter-spacing: 1px;
    color: #fdbe0d;
    padding-bottom: 15px;
    border: 1px dashed;
    padding-left: 4px;
}
/*reset style*/
.search-area >>> .el-input__inner{
    width: 80%;
    /* position: relative; */
}
.search-area >>> .el-input{
    
}
.el-icon-search{
    color:#2283f9;
    font-size: 28px;
    /* position: absolute; */
}
.search-area >>> .el-input__suffix{
    width: 25%;
}
.radio >>> .el-radio{
    /* content: '*'; */
    color:white;
    cursor: pointer;
    margin-right: 103px;
}
/* .search-area>>>.el-radio-group,.radio{
    margin-top: 0.367647rem;
    margin-right: -0.735294rem;
    margin-right: 75px;
} */
.radio >>> .el-radio__input.is-checked+.el-radio__label {
    color: white;
}
.foot >>>.el-pagination.is-background .el-pager li {
    margin: 0 23px;
    
    color: #606266;
    min-width: 39px;
    border-radius: 2px;
    height: 39px;
    line-height: 39px;
    box-sizing: border-box;
    text-align: center;
}
.foot >>>.el-pagination.is-background .el-pager li:not(.disabled):hover {
    color: #409EFF;
    
}
.foot >>> .el-pagination.is-background .el-pager li:not(.disabled).active {
    background-color: #409EFF;
    color: #FFF;
    width: 39px;
    height: 39px;
    line-height: 39px;
    border: 0;
    border-radius: 0 !important;
    /* background-color: rgba(237,237,237,0); */
    /* color: #606266; */
}
.foot >>>.el-pagination.is-background .btn-next,.foot>>>.el-pagination.is-background .btn-prev {
    margin: 0 25px;
    background-color: #f4f4f5;
    color: #606266;
    min-width: 39px;
    height: 39px;
    line-height: 39px;
    
}

</style>